<template>
  <div>
    <v-divider />
    <v-container>
      <v-row>
        <v-col cols="4">
          <div class="d-flex">
            <v-card class="flex-1 mr-2" flat min-height="600">
              <v-list dense>
                <v-subheader>文章列表</v-subheader>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item
                    v-for="(item, i) in lists"
                    :key="i"
                    @click="changeDefault(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title" class="text-body-1"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
            <v-divider vertical />
          </div>
        </v-col>
        <v-col cols="8">
          <div class="pa-8">
            <h1 class="text-h4 font-weight-bold mb-4">{{defaultTitle}}</h1>
            <div v-html="defaultHtml"></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lists: [],
      selectedItem: "0",
      defaultHtml: "",
      defaultTitle: "",
      defaultId: ""
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getlist() {
      this.$api.help.get_help_list().then(res => {
        if (res.status == 1) {
          //得到ID
          if (this.$route.query && this.$route.query.id) {

            for (let i in res.data.records) {
              if (res.data.records[i].help_center_id == this.$route.query.id) {
                this.defaultId = res.data.records[i].help_center_id;
                this.defaultHtml = res.data.records[i].content;
                this.defaultTitle = res.data.records[i].title;
               this.selectedItem=Number(i);
   
             
              }
            }
              
          } else {
            this.defaultId = res.data.records[0].help_center_id;
            this.defaultHtml = res.data.records[0].content;
            this.defaultTitle = res.data.records[0].title;
            this.selectedItem=0;
          }
           this.lists = res.data.records;
   
        }
      });
    },
    changeDefault(item) {
      this.defaultHtml = item.content;
      this.defaultTitle = item.title;
      this.defaultId = item.help_center_id;
    }
  }
};
</script>
<style >
img{
  max-width: 100%;
}
</style>